export const options = [
  {
    label: 'id',
    prop: 'Id',
    width: 80
  },
  {
    label: '用户id',
    prop: 'UserId',
    width: 80
  },
  {
    label: '订单id',
    prop: 'OrderId',
    width: 80
  },
  {
    label: '交易对',
    prop: 'TradingPair',
    width: 120
  },
  {
    label: '操作描述',
    prop: 'Action',
    width: 120
  },
  {
    label: '卖出金额',
    prop: 'AmountIn'
  },
  {
    label: '接收数量',
    prop: 'ReceivedAmount'
  },
  {
    label: '状态',
    prop: 'Status',
    width: 80
  },
  {
    label: '交易哈希',
    prop: 'TradeHash',
    hide: true
  },
  {
    label: '错误信息',
    prop: 'Details',
    hide: true
  },
  {
    label: '交易时间',
    prop: 'CreatedAt'
  },
  {
    label: '操作',
    prop: 'action',
    width: 160
  }
]
